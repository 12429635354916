//
//
//
//
//
//
//
//
//

export default {
  name: "LoadingModal",

  computed: {
    open: function () {
      return this.$store.state.loading.popUpLoading || false
    }
  }
}
